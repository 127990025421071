/* eslint css-modules/no-unused-class: [2, {camelCase: true, markAsUsed: [
 silver-price, gold-price, diamond-price, get-silver-btn, get-gold-btn, get-diamond-btn
]}] */

import { PLAN_TYPES } from "@goalteller/app-kit/api/rest/endpoints/billing/constants";
import { fetchPlans } from "@goalteller/app-kit/api/rest/endpoints/public";
import UiContentLoader from "@goalteller/app-kit/components/loaders/UiContentLoader";
import useBreakpoints from "@goalteller/app-kit/hooks/useBreakpoints";
import { openNewTab } from "@goalteller/app-kit/tools/crossBrowser";
import { Button } from "@goalteller/app-kit/ye-design/components/atoms";
import { IconArrowLeft, IconCheck } from "@tabler/icons-react";
import clsx from "clsx";
import { upperFirst } from "lodash-es";
import Image from "next/image";
import React from "react";
import { useAsync } from "react-use";

import gtDiamondPlan from "../images/pricing/gt-diamond-plan.svg";
import gtGoldPlan from "../images/pricing/gt-gold-plan.svg";
import gtSilverPlan from "../images/pricing/gt-silver-plan.svg";
import styles from "./pricingTable.module.css";

const FEATURES_LIST = [
  { key: "monthlyInvestmentReviews", label: "Monthly Investment Reviews" },
  { key: "auditOfNominations", label: "Audit of Nominations" },
  { key: "financialGoalManagement", label: "Financial Goal Management" },
  { key: "freeExecution", label: "Free Execution (Mutual Funds, Bonds, etc.)" },
  { key: "monthlyCall", label: "Monthly Call with Relationship Manager" },
  {
    key: "dedicatedRelationshipManager",
    label: "Dedicated Relationship Manager",
  },
  { key: "customisedProducts", label: "Customized Products" },
  {
    key: "whatsappGroup",
    label: "Dedicated WhatsApp Group with Founding Member",
  },
  { key: "webinarInvites", label: "Exclusive Webinar Invites" },
  { key: "wealthManagers", label: "Liaising with Other Wealth Managers" },
  { key: "customisedReporting", label: "Customized Reporting" },
  { key: "taxOptimization", label: "Tax Consolidation and Optimization" },
  { key: "inPersonReviews", label: "In-person Reviews" },
  { key: "oneOnOneCalls", label: "1-on-1 Calls with Fund Managers" },
] as const;

const PLANS = [
  {
    durationMonths: 6,
    features: new Set([
      "monthlyInvestmentReviews",
      "auditOfNominations",
      "financialGoalManagement",
      "freeExecution",
      "monthlyCall",
    ]),

    tier: "silver",
  },
  {
    durationMonths: 6,
    features: new Set([
      "monthlyInvestmentReviews",
      "auditOfNominations",
      "financialGoalManagement",
      "freeExecution",
      "monthlyCall",
      "dedicatedRelationshipManager",
      "customisedProducts",
      "whatsappGroup",
      "webinarInvites",
    ]),

    tier: "gold",
  },
  {
    durationMonths: 6,
    features: new Set(FEATURES_LIST.map((feature) => feature.key)),

    tier: "diamond",
  },
] as const;

const PLAN_LOGOS = {
  diamond: gtDiamondPlan,
  gold: gtGoldPlan,
  silver: gtSilverPlan,
} as const;

function PricingTable({ className }: { className?: string }) {
  const { isMobile, isTablet } = useBreakpoints();
  const planData = useAsync(async () => {
    const { data } = await fetchPlans();
    return data;
  });

  return (
    <div className={clsx(styles.pricingTable, className)}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.featuresHeader}>Features</th>

            {PLANS.map((plan, index) => (
              <th className={styles.planHeaderCell} key={index}>
                <div className={styles.planHeader}>
                  {(isMobile || isTablet) && (
                    <div className={clsx(styles.tag, styles.swipeToCompare)}>
                      <IconArrowLeft />
                      Swipe to compare
                    </div>
                  )}
                  <div>
                    {
                      {
                        diamond: (
                          <div className={styles.tag}>Most Benefits</div>
                        ),
                        gold: <div className={styles.tag}>Popular</div>,
                        silver: <>&nbsp;</>,
                      }[plan.tier]
                    }
                  </div>
                  <div className={styles.planTier}>
                    {PLAN_LOGOS[plan.tier] && (
                      <Image
                        alt=""
                        className={styles.planLogo}
                        src={PLAN_LOGOS[plan.tier]}
                      />
                    )}
                    {upperFirst(plan.tier)} Tier
                  </div>
                  <div className={styles.planPriceContainer}>
                    <div
                      className={clsx(
                        styles.planPrice,
                        styles[`${plan.tier}-price`],
                      )}
                    >
                      {planData.loading ? (
                        <UiContentLoader />
                      ) : (
                        <>
                          ₹{" "}
                          {
                            planData.value?.find(
                              (p) => p.code === PLAN_TYPES[plan.tier].code,
                            )?.amount
                          }
                        </>
                      )}
                    </div>
                    <div className={styles.planDuration}>
                      {plan.durationMonths} Months Plan
                    </div>
                  </div>
                  <Button
                    className={clsx(
                      styles.getPlanBtn,
                      styles[`get-${plan.tier}-btn`],
                    )}
                    isFullWidth
                    onClick={() => {
                      openNewTab(
                        `${process.env.NEXT_PUBLIC_WEBAPP_GOALTELLER}/payment/checkout?payment_plan=${PLAN_TYPES[plan.tier].code}`,
                      );
                    }}
                    size="small"
                    variant="primary"
                  >
                    Get {upperFirst(plan.tier)} Tier
                  </Button>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {FEATURES_LIST.map(({ key, label }, index) => (
            <tr className={styles.featureRow} key={key}>
              <td
                className={clsx(
                  styles.featureLabel,
                  index === FEATURES_LIST.length - 1 && styles.lastFeatureLabel,
                )}
              >
                {label}
              </td>
              {PLANS.map((plan, planIndex) => (
                <td className={styles.featureCell} key={planIndex}>
                  {plan.features.has(key) ? (
                    <IconCheck className={styles.iconCheck} stroke={3} />
                  ) : (
                    "-"
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default PricingTable;
